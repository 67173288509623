// Body
$body-bg: #fff;
// Typography
$secondary-font: "Raleway", sans-serif;
$primary-font: "Raleway", sans-serif;
$fa-font-path: "../fonts" !default;
$header-height: 60px;
$logo-height: 45px;
$text-color: #0058b2;
$color-primary: #FF6D00;
$color-secondary: #BA9348;
$color-alternate: #fffaf1;
$headings-font-weight: 600 !default;
// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: #555;
$btn-primary-background: $color-primary;

// Modal
$modal-xl: 1140px !default;

//Image
$img-path: "/images/" !default;
$lg-path-fonts: '/fonts'!default;
$lg-path-images: '/images/'!default;

//** Global textual link color.
$link-color:            black !default;

//== Components
$border-radius-base:        0 !default;
$border-radius-large:       0 !default;
$border-radius-small:       0 !default;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;


// Liens
