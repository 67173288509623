html {width: 100vw; overflow-x: hidden;}
body {
    padding-top: $header-height;
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    color: #555;
    width:100vw; overflow-x: hidden;
}

.height-40vh {height:40vh; overflow:hidden;}
.height-30vh {height:30vh; overflow:hidden;}
.height-300 {height: 300px;}

.vw-100 {width: 100vw;}
.vh-100 {height: 100vh;}
.vh-90 {height: 90vh;}
.vh-80 {height: 80vh;}

h1 {
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    color : #000;
    margin-bottom: 30px; font-size: 24px!important;
}
h2 {font-family: "Raleway", sans-serif;
    font-weight: 300;
    color : #000;
    margin-bottom: 30px; font-size: 18px!important;}

.title-content h1{ margin-bottom: 30px; font-size: 30px!important;}
.text-content {font-size: 14px !important;}
.text-white {color:white;}
.center-img {vertical-align: middle;}
.relative {position:relative;}
.fixed {position:fixed;}
.layer-dark {position:absolute; top:0; bottom:0; right:0; left:0; background-color: rgba(0,0,0,0.2);}
.layer-light {position: absolute; top:0; left:0; right: 0; bottom: 0; background-color: rgba(255,255,255,0.2);}
.img-thumbnail {border-radius: 0; border-width: 3px;}

.gray-hover:hover, .grayscale {
    filter: grayscale(100%);
}

.text-10 {font-size: 10px;}
.text-12 {font-size: 12px;}
.text-14 {font-size: 14px;}
.text-16 {font-size: 16px;}
.text-18 {font-size: 18px;}



a {
  &:focus,
  &:hover,
  &:active {
    color: darken($color-primary, 10%);
    text-decoration: none;
  }
}

.h-300 {height: 300px;}
.h-400 {height: 400px;}
.h-500 {height: 500px;}

.btn {
  border-width: 2px;
}

.bg-primary {
  background-color: $color-primary !important;
  h1,h2 {color:#FFF;}
}

.bg-secondary {
  background-color: $color-secondary !important;
  h1,h2 {color:#FFF;}
}

.text-primary {
  color: $color-primary !important;
  h1,h2 {color:#FFF;}
}

.text-secondary {
  color: $color-secondary !important;
}

.btn-primary {
  background-color: $color-primary;
  border-color: $color-primary;
  &:hover,
  &:focus,
  &:active {
    background-color: darken($color-primary, 10%);
    border-color: darken($color-primary, 10%);
  }
}

.btn-secondary {
  background-color: $color-secondary;
  border-color: $color-secondary;
  &:hover,
  &:focus,
  &:active {
    background-color: darken($color-secondary, 10%);
    border-color: darken($color-secondary, 10%);
  }
}

.square {
    width: 100%!important;
    padding-bottom: 100%!important;
    background-repeat: no-repeat!important; 
    background-position:center center!important;
    background-size: cover!important;
}



textarea:hover, input, input:after, input:hover, textarea:active, input:active, textarea:focus, input:focus, button:focus, button:active, button:hover, label:focus, .btn:active, .btn.active {
    outline:0px !important;
    //-webkit-appearance:none !important;
    box-shadow: none;
}


.pointer {cursor: pointer;}
.bg-white { background:white; }
.bg-alternate { background-color:$color-alternate; }
.bg-transparent { background-color:transparent }

#remonte {
    position:fixed; 
    //right:calc(50% - 30px); 
    right:30px;
    bottom:20px; cursor:pointer;  opacity:0.8; z-index:1000;
    transform-origin: center;
    font-size: 60px;
    color:#000;
    padding: 0 15px;
    line-height: 40px;

    &:hover {
        opacity:1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    }
    
    &.active {
        transform: rotate(180deg);
        background: rgba(255,255,255,0.9);
    }
}

 @media only screen and (min-width:992px) {
     body {padding-top:$header-height;}
     
    h1 {
        font-family: "Raleway", sans-serif;
        font-weight: 300;
        color : #000;
        margin-bottom: 30px; font-size: 30px!important;
    }
    .title-content h1{ margin-bottom: 30px; font-size: 46px;}
    .text-content {font-size: 16px !important;}
     
    .anim { -webkit-transition: all 0.3s ease-in-out; -moz-transition: all 0.3s ease-in-out; -ms-transition: all 0.3s ease-in-out; -o-transition: all 0.3s ease-in-out; transition: all 0.3s ease-in-out; }
    .anim_slow { -webkit-transition: all 0.5s ease-in-out; -moz-transition: all 0.5s ease-in-out; -ms-transition: all 0.5s ease-in-out; -o-transition: all 0.5s ease-in-out; transition: all 0.5s ease-in-out; }
    [data-link] {cursor: pointer;}
    a { @extend .anim; }
    .zoom {
        @extend .anim_slow;
        &:hover {
            display: inline-block;
            z-index:1000;
            transform: scale(1.1);
            cursor: pointer;
        //border:solid 2px #FFF;
        //box-shadow: 3px 3px 10px #222;
        }
    }
    .zoom-1 {
        @extend .anim_slow;
        &:hover {
            display: inline-block;
            transform: scale(1.05);
            cursor: pointer;
        }
    }
    .zoom-out {
        @extend .anim;
        &:hover {
            display: inline-block;
            transform: scale(0.95);
            cursor: pointer;
        }
    }
}