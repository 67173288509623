// Styling for the navbar

// LOGO CENTERED

$nth-nav-item-that-needs-margin-right     : 2;
$width-of-navbar-brand-or-logo-in-px      : 310px;
$padding-x-for-navbar-brand-or-logo-in-px : 40px;
@media (max-width:767px) {
    #mainNav .navbar-brand img {height: 30px!important;}
    #mainNav .navbar-nav li.nav-item a.nav-link {color: #555!important;}
    body {padding-top: 30px!important;}
}

@media (min-width:768px) {
	.position-md-absolute {
		position: absolute;
	}
	.navbar-nav.logo-centered .nav-item:nth-child(#{$nth-nav-item-that-needs-margin-right}) {
		margin-right: $width-of-navbar-brand-or-logo-in-px + ($padding-x-for-navbar-brand-or-logo-in-px * 2);
	}
	.navbar-nav .navbar-brand img {height: $logo-height;}
}

.footer-bottom { position: fixed; bottom: 0; left:0; width:100vw; z-index: 100;}

#mainNav {
    font-family: $secondary-font;
    box-shadow: 0px 1px 10px #444;
    
    .navbar-brand {
        color: #555;
        img {height: $logo-height;}
    }
    .navbar-nav {
        letter-spacing: 0.0625rem;
        li.nav-item {
            a.nav-link {
                @extend .anim;
                color: white;
                font-size: 13px;
                //background-color: $color-primary;
                border-top: solid 8px transparent;
                &:hover {
                    color: $color-secondary;
                }
                &:active,
                &:focus {
                    color: $color-primary;
                }
                
            }
            &:not(:last-child) {
                border-bottom: solid 1px #f9f9f9;
            }
            .dropdown-menu {
                padding: 0;
                margin:  0;
            }
            .dropdown-item {
                display: block;
                width: 100%;
                clear: both;
                color: #FFF;
                text-align: inherit;
                white-space: nowrap;
                font-size: 13px;
                background-color: $color-primary;
                opacity: 0.9;
                padding : 15px 20px;
            }
        }
    }
}

@keyframes slideIn {
    0% {
        transform: translateY(1rem);
        opacity: 0;
    }
    100% {
        transform:translateY(0rem);
        opacity: 1;
    }
    0% {
        transform: translateY(1rem);
        opacity: 0;
    }
}

@-webkit-keyframes slideIn {
    0% {
        -webkit-transform: transform;
        -webkit-opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        -webkit-opacity: 1;
    }
    0% {
        -webkit-transform: translateY(1rem);
        -webkit-opacity: 0;
    }
}

.slideIn {
    -webkit-animation-name: slideIn;
    animation-name: slideIn;
}


@media(min-width:768px) {
    #mainNav {
        height: $header-height;
        padding:10px 0;
        -webkit-transition: padding-top 0.3s, padding-bottom 0.3s;
        -moz-transition: padding-top 0.3s, padding-bottom 0.3s;
        transition: padding-top 0.3s, padding-bottom 0.3s;
        .navbar-brand {
            padding-top:0; padding-bottom: 0;
            font-size: 2em;
            -webkit-transition: font-size 0.3s;
            -moz-transition: font-size 0.3s;
            transition: font-size 0.3s;
        }
        .navbar-nav {
            letter-spacing: 0.0625rem;
            li.nav-item {
                a.nav-link {
                    width:150px;
                    font-size: 13px;
                    color: #555;
                    background-color: transparent;
                    
                    &:hover {
                        color: $color-primary;
                    }
                    &:active,
                    &:focus {
                        color: $color-primary;
                    }
                    &.active {
                        color: $color-primary;
                    }
                }
                .dropdown-item {
                    color: #555;
                    background-color: transparent;
                }
                &:not(:last-child) {
                    border-bottom: 0;
                }
            }
        }
    }
}